import { AlertTitle, Button, duration, FormControl } from "@mui/material";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import OrderList from "./components/OrderList/OrderList";
import Sale from "./components/Sale/Sale";
import styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AnimatedHeader from "./components/AnimatedHeader/AnimatedHeader";
import AnimatedSubmitButton from "./components/AnimatedSubmitButton/AnimatedSubmitButton";
import {
  useCalcValueQuery,
  useClearCartMutation,
  useGetDateTimeQuery,
  useGetUserByTgIDQuery,
  useLazySendOrderQuery,
  userApiSlice,
} from "../../api/userAPI";

import { useLazyCheckHallQuery } from "../../api/hallsAPI";
import HallOrder from "./components/HallOrder/HallOrder";
import { AlertMessage } from "../../shared/types/errorAlertMessage";
import { createPortal } from "react-dom";
import { Slide } from "@mui/material";
import { AlertComponent } from "../../ui/Alert/Alert";
import {
  CartData,
  UpdateCartFood,
  UpdateCartInfo,
  UpdateCartService,
  WebappDataFood,
  WebappDataGood,
  WebappDataHall,
  WebappDataRootOrder,
  WebappDataServices,
  WebappDataUser,
} from "../../api/models/userAPIModel";
import { useAppDispatch } from "../../shared/hooks/reduxTypes";
import LoadingScreen from "./components/Loading/Loading";
import { saleValue } from "./functions/saleValue";
import { useTelegram } from "../../shared/hooks/useTelegram";
import moment from "moment";
import DateTimePicker from "./components/DateTimePicker/DateTimePicker";
import { useTheme } from "../../shared/hooks/useTheme";

type NoNullFields<Ob> = { [K in keyof Ob]: NonNullable<Ob[K]> };

interface ISessionDate {
  date: Date | null;
  time: { hours: number; minutes: number } | null;
}

function isNonNullSessionDate(
  sessionDate: ISessionDate
): sessionDate is NoNullFields<ISessionDate> {
  return sessionDate.date !== null && sessionDate.time !== null;
}

function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${year}-${month}-${day}`;
}

function calculateHoursDifference(
  date1: NoNullFields<ISessionDate>,
  date2: NoNullFields<ISessionDate>
): number {
  // Преобразуем даты в миллисекунды с учетом времени
  const timestamp1 = Date.UTC(
    date1.date.getFullYear(),
    date1.date.getMonth(),
    date1.date.getDate(),
    date1.time.hours,
    date1.time.minutes
  );
  const timestamp2 = Date.UTC(
    date2.date.getFullYear(),
    date2.date.getMonth(),
    date2.date.getDate(),
    date2.time.hours,
    date2.time.minutes
  );

  console.log(date1.date.getMonth(), date1.date.getDate());

  // Рассчитываем разницу в миллисекундах
  const differenceInMilliseconds = timestamp2 - timestamp1;

  // Преобразуем разницу в часы с точностью до десятичных
  const hoursDifference = differenceInMilliseconds / (1000 * 60 * 60);

  return hoursDifference;
}

const Order = () => {
  const dispatch = useAppDispatch();
  const { webApp } = useTelegram();

  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
  );
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({
    status: false,
    message: "",
    severity: "error",
  });
  const [price, setPrice] = useState<number | null>(null);
  const [isActiveStartDate, setIsActiveStartDate] = useState(false);
  const [isActiveEndDate, setIsActiveEndDate] = useState(false);
  const [startSessionDate, setStartSessionDate] = useState<ISessionDate>({
    date: null,
    time: null,
  });
  const [errorSessionTime, setErrorSessionTime] = useState<string | null>(null);
  const [endSessionDate, setEndSessionDate] = useState<ISessionDate>({
    date: null,
    time: null,
  });
  const [sale, setSale] = useState(0);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [checkHall] = useLazyCheckHallQuery();
  const [sendOrder] = useLazySendOrderQuery();
  const [clearCart] = useClearCartMutation();
  const cartDataRef = useRef<CartData>({ food: {}, services: {}, goods: {} });
  const hallDataRef = useRef({ price: 0, hours: 0 });
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const dateTimePickerRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  const addExtraNull = (num: number) => {
    if (num < 10) {
      return `0${num}`;
    }

    return num;
  };

  const calculatePrice = () => {
    let priceValue = 0;
    let percSale = 0;

    if (
      isNonNullSessionDate(startSessionDate) &&
      isNonNullSessionDate(endSessionDate)
    ) {
      const diffTime = calculateHoursDifference(
        startSessionDate,
        endSessionDate
      );

      percSale = saleValue(diffTime);

      if (diffTime > 0) priceValue += hallDataRef.current.price * diffTime;
    }

    const arrayFood = Object.entries(cartDataRef.current.food);
    const arrayServices = Object.entries(cartDataRef.current.services);
    const arrayGoods = Object.entries(cartDataRef.current.goods);

    for (let i = 0; i < arrayFood.length; i++) {
      let elem = arrayFood[i][1];
      priceValue += elem.quantity * elem.price;
    }

    for (let i = 0; i < arrayGoods.length; i++) {
      let elem = arrayGoods[i][1];

      priceValue += elem.quantity * elem.price;
    }

    for (let i = 0; i < arrayServices.length; i++) {
      let elem = arrayServices[i][1];
      priceValue += elem.price;
    }

    let saleVal = (priceValue * percSale) / 100;
    priceValue -= saleVal;
    return parseFloat(priceValue.toFixed(1));
  };

  const setCart = useMemo(() => {
    let info: UpdateCartInfo = {
      food: {},
      services: {},
      goods: {},
    };

    if (userData) {
      const food = userData.data.food;
      let foodBody: Record<string, UpdateCartFood> = {};

      for (const key in food) {
        foodBody[key] = { quantity: food[key].quantity };
      }

      const goods = userData.data.goods;
      let goodBody: Record<string, UpdateCartFood> = {};

      for (const key in goods) {
        goodBody[key] = { quantity: goods[key].quantity };
      }

      const services = userData.data.services;
      let servicesBody: Record<string, UpdateCartService> = {};

      for (const key in services) {
        servicesBody[key] = {};
      }

      info.food = foodBody;
      info.goods = goodBody;
      info.services = servicesBody;
    }

    return info;
  }, []);

  const { data: cartData, isLoading: cartLoading } = useCalcValueQuery(
    setCart,
    { skip: userData ? false : true }
  );

  const { data: dateData } = useGetDateTimeQuery(undefined);

  const changeProductOrder = useCallback(() => {
    setPrice(calculatePrice());
  }, [endSessionDate, startSessionDate]);

  useEffect(() => {
    if (alertMessage.status) {
      timerRef.current = setTimeout(() => {
        setAlertMessage((prev) => ({ ...prev, status: false }));
      }, 2000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [alertMessage.status]);

  useEffect(() => {
    if (cartData) {
      let fInfo = cartData.food;
      let gInfo = cartData.goods;
      let sInfo = cartData.services;
      let foodsInfo: CartData["food"] = {};
      let servicesInfo: CartData["services"] = {};
      let goodsInfo: CartData["goods"] = {};
      for (const key in fInfo) {
        let elem = fInfo[key];
        foodsInfo[key] = {
          name: elem.name,
          weight: elem.weight,
          price: elem.price_per_unit,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }
      for (const key in gInfo) {
        let elem = gInfo[key];
        goodsInfo[key] = {
          name: elem.name,
          weight: elem.weight,
          price: elem.price_per_unit,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }
      for (const key in sInfo) {
        let elem = sInfo[key];
        servicesInfo[key] = {
          name: elem.name,
          time: elem.for_time,
          price: elem.total_price,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }

      foodsInfo = JSON.parse(JSON.stringify(foodsInfo));
      goodsInfo = JSON.parse(JSON.stringify(goodsInfo));

      servicesInfo = JSON.parse(JSON.stringify(servicesInfo));
      cartDataRef.current = JSON.parse(
        JSON.stringify({
          food: foodsInfo,
          services: servicesInfo,
          goods: goodsInfo,
        })
      ) as CartData;

      if (cartData.halls) {
        const hallInfo = Object.entries(cartData.halls)[0]
          ? Object.entries(cartData.halls)[0][1]
          : undefined;

        if (hallInfo) {
          hallDataRef.current = {
            hours: hallInfo.hours,
            price: hallInfo.price_per_hour,
          };
        }
      }

      setPrice(calculatePrice());

      const update = userApiSlice.util.updateQueryData(
        "getUserByTgID",
        webApp?.initDataUnsafe?.user?.id,
        (draft) => {
          draft.data.food = foodsInfo;
          draft.data.goods = goodsInfo;
          draft.data.services = servicesInfo;
          return draft;
        }
      );
      dispatch(update);
    }
  }, [cartLoading]);

  useEffect(() => {
    if (
      isNonNullSessionDate(startSessionDate) &&
      isNonNullSessionDate(endSessionDate)
    ) {
      const diffTime = calculateHoursDifference(
        startSessionDate,
        endSessionDate
      );

      if (diffTime < 0) {
        setErrorSessionTime(
          "Время начала сеанса не может быть больше времени окончания"
        );
        setPrice(calculatePrice());
        return;
      }

      if (diffTime > 72) {
        setErrorSessionTime("Зал нельзя арендовать более чем на 72 часа");
        return;
      }

      setPrice(calculatePrice());
      setSale(saleValue(diffTime));
      setErrorSessionTime(null);
    }
  }, [startSessionDate, endSessionDate]);

  const formSubmit = async () => {
    setFormSubmitLoading(true);
    if (cartData && userData) {
      if (cartData?.halls && Object.entries(cartData?.halls)[0] === undefined) {
        setAlertMessage({
          status: true,
          message: "Выберите зал",
          severity: "error",
        });

        setFormSubmitLoading(false);

        return;
      }

      if (startSessionDate.date === null) {
        setAlertMessage({
          status: true,
          message: "Выберите дату начала сеанса",
          severity: "error",
        });

        setFormSubmitLoading(false);
        return;
      }

      if (startSessionDate.time === null) {
        setAlertMessage({
          status: true,
          message: "Выберите время начала сеанса",
          severity: "error",
        });
        setFormSubmitLoading(false);
        return;
      }

      if (endSessionDate.date === null) {
        setAlertMessage({
          status: true,
          message: "Выберите дату конца сеанса",
          severity: "error",
        });

        setFormSubmitLoading(false);
        return;
      }

      if (endSessionDate.time === null) {
        setAlertMessage({
          status: true,
          message: "Выберите время конца сеанса",
          severity: "error",
        });

        setFormSubmitLoading(false);
        return;
      }

      if (
        isNonNullSessionDate(startSessionDate) &&
        isNonNullSessionDate(endSessionDate)
      ) {
        const differenceTime = calculateHoursDifference(
          startSessionDate,
          endSessionDate
        );

        if (cartData?.halls) {
          const reqCheckHall = await checkHall({
            id: Object.entries(cartData?.halls)[0][0],
            hours: differenceTime,
            date: formatDate(startSessionDate.date),
            time: `${addExtraNull(startSessionDate.time.hours)}:${addExtraNull(
              startSessionDate.time.minutes
            )}`,
          });

          if (reqCheckHall.data?.available === false) {
            setAlertMessage({
              status: true,
              message: "Зал недоступен на это время",
              severity: "error",
            });
            setFormSubmitLoading(false);
          } else {
            const userInfo: WebappDataUser = {
              tg_id: webApp?.initDataUnsafe?.user?.id,
              phone: String(userData?.phone_number),
            };
            const cartHallInfo = Object.entries(cartData.halls)[0];
            let hallInfo: Record<string, WebappDataHall> = {};

            const dateValue = startSessionDate.date;

            const dateValueStr = `${addExtraNull(
              dateValue.getDate()
            )}.${addExtraNull(dateValue.getMonth() + 1)}.${addExtraNull(
              dateValue.getFullYear() % 1000
            )}, ${addExtraNull(startSessionDate.time.hours)}:${addExtraNull(
              startSessionDate.time.minutes
            )}`;

            if (cartHallInfo) {
              hallInfo[String(cartHallInfo[0][0])] = {
                name: cartData.halls[String(cartHallInfo[0][0])].name,
                datetime: dateValueStr,
                hours: `${differenceTime}`,
              };
            }

            const dataFood: Record<string, WebappDataFood> = {};
            const dataGood: Record<string, WebappDataGood> = {};
            const dataServices: Record<string, WebappDataServices> = {};

            if (userData) {
              const foodList = userData.data.food;

              for (let key in foodList) {
                let elem = foodList[key];
                dataFood[key] = { quantity: elem.quantity };
              }

              const goodList = userData.data.goods;

              for (let key in goodList) {
                let elem = goodList[key];
                dataGood[key] = { quantity: elem.quantity };
              }

              const servicesList = userData.data.services;

              for (let key in servicesList) {
                dataServices[key] = { quantity: 1 };
              }
            }

            let orderInfo: WebappDataRootOrder = {
              Order: {
                user: userInfo,
                info: {
                  halls: hallInfo,
                  foods:
                    Object.entries(dataFood).length === 0
                      ? undefined
                      : dataFood,
                  goods:
                    Object.entries(dataGood).length === 0
                      ? undefined
                      : dataGood,
                  services:
                    Object.entries(dataServices).length === 0
                      ? undefined
                      : dataServices,
                },
              },
            };

            const reqSendOrder = await sendOrder(orderInfo);

            if (reqSendOrder.data && userData.id) {
              await clearCart(String(userData.id));
              webApp.close();
            }

            setFormSubmitLoading(false);
          }
        }
      }
    }
  };

  const formatSessionTime = (sessionDate: ISessionDate) => {
    if (!sessionDate.date || !sessionDate.time) {
      return ""; // или любое другое значение по умолчанию, если данные отсутствуют
    }

    const day = sessionDate.date.getDate().toString().padStart(2, "0");
    const month = (sessionDate.date.getMonth() + 1).toString().padStart(2, "0");
    const year = sessionDate.date.getFullYear().toString().slice(-2);
    const hours = sessionDate.time.hours.toString().padStart(2, "0");
    const minutes = sessionDate.time.minutes.toString().padStart(2, "0");

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };

  if (cartLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <form
        style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        <div className={styles.container}>
          <HallOrder
            plusHourClick={() => {
              hallDataRef.current.hours += 1;
              setPrice(calculatePrice());
            }}
            minusHourClick={() => {
              hallDataRef.current.hours -= 1;
              setPrice(calculatePrice());
            }}
            data={cartData?.halls}
            isLoading={cartLoading}
          />

          <AnimatedHeader>ТОВАРЫ И УСЛУГИ:</AnimatedHeader>

          <Sale value={sale} />

          <OrderList
            plusClick={changeProductOrder}
            minusClick={changeProductOrder}
            clickDelete={changeProductOrder}
            clearCart={changeProductOrder}
            cartData={cartDataRef}
          />

          <SectionHeader>БРОНИРОВАНИЕ:</SectionHeader>

          <div
            data-value={theme === 'dark' ? "dark" : "light"}
            className={styles.formTime}
          >
            <label
              onClick={(e) => {
                e.stopPropagation();
                setIsActiveStartDate(!isActiveStartDate);
                setIsActiveEndDate(false);

                if (!isActiveStartDate) {
                  requestAnimationFrame(() => {
                    if (dateTimePickerRef?.current) {
                      dateTimePickerRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  });
                }
              }}
              className={`${styles.inputTime}`}
            >
              <span>Время начала:</span>
              <input
                value={formatSessionTime(startSessionDate)}
                className={`${isActiveStartDate && styles.active}`}
                readOnly
              />
            </label>

            <label
              onClick={(e) => {
                e.stopPropagation();
                setIsActiveEndDate(!isActiveEndDate);
                setIsActiveStartDate(false);

                if (!isActiveEndDate) {
                  requestAnimationFrame(() => {
                    if (dateTimePickerRef?.current) {
                      dateTimePickerRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }
                  });
                }
              }}
              className={`${styles.inputTime}`}
            >
              <span>Время конца:</span>
              <input
                className={`${isActiveEndDate && styles.active}`}
                value={formatSessionTime(endSessionDate)}
                readOnly
              />
            </label>
          </div>
          {(isActiveStartDate || isActiveEndDate) && (
            <DateTimePicker
              onClickWindow={() => {
                setIsActiveEndDate(false);
                setIsActiveStartDate(false);
              }}
              onChangeDate={(date) => {
                if (isActiveStartDate) {
                  setStartSessionDate((state) => {
                    return { ...state, date: date };
                  });
                  return;
                }

                if (isActiveEndDate) {
                  setEndSessionDate((state) => {
                    return { ...state, date: date };
                  });
                }
              }}
              onChangeTime={(time) => {
                if (isActiveStartDate) {
                  setStartSessionDate((state) => {
                    return { ...state, time: time };
                  });
                  return;
                }

                if (isActiveEndDate) {
                  setEndSessionDate((state) => {
                    return { ...state, time: time };
                  });
                }
              }}
              dateValue={
                isActiveStartDate ? startSessionDate.date : endSessionDate.date
              }
              time={
                isActiveStartDate ? startSessionDate.time : endSessionDate.time
              }
              ref={dateTimePickerRef}
            />
          )}

          <>
            {errorSessionTime && (
              <div className={styles.errorTime}>{errorSessionTime}</div>
            )}
          </>
        </div>

        {price !== null && (
          <AnimatedSubmitButton
            disabled={formSubmitLoading || errorSessionTime !== null}
            type="submit"
            className={styles.submitBtn}
          >
            {`ОФОРМИТЬ ЗАКАЗ (${price}р.)`}
          </AnimatedSubmitButton>
        )}
      </form>

      {createPortal(
        <Slide
          style={{
            position: "fixed",
            top: 15,
            left: 20,
            right: 20,
            width: "auto",
            zIndex: 16,
          }}
          timeout={300}
          direction="down"
          in={alertMessage.status}
          mountOnEnter
          unmountOnExit
        >
          <AlertComponent severity={alertMessage.severity}>
            <>
              <AlertTitle className={styles.alertTitle}>
                {alertMessage.message}
              </AlertTitle>
            </>
          </AlertComponent>
        </Slide>,
        document.body
      )}
    </>
  );
};

export default Order;
