import { useEffect, useMemo } from "react";
import {
  useGetHallsQuery,
  useLazyGetHallsQuery,
  useGetFilteredHallsMutation,
} from "../../../../api/hallsAPI";
import HallCard from "../Card/HallCard";
import AnimatedCard from "./AnimatedCard/AnimatedCard";
import LoadingCardList from "./LoadingCardList/LoadingCardList";
import styles from "./styles.module.scss";
import { useAppSelector } from "../../../../shared/hooks/reduxTypes";
import { transformPathToPhoto } from "../../../../shared/functions/transformPathToPhoto";
const HallList = () => {
  const capacity = useAppSelector(
    (state) => state.hallsFilterForm.req_capacityValue
  );
  const price = useAppSelector((state) => state.hallsFilterForm.req_priceValue);
  const dateValue = useAppSelector((state) => state.hallsFilterForm.req_date);
  const sort = useAppSelector((state) => state.hallsFilterForm.req_sortValue);
  const hour = useAppSelector((state) => state.hallsFilterForm.req_hour);
  const minute = useAppSelector((state) => state.hallsFilterForm.req_minute);

  const addExtraNull = (num: number) => {
    if (num < 10) {
      return `0${num}`;
    }

    return num;
  };

  const setFilterQuery = useMemo(() => {
    return {
      max_people: capacity === 0 ? undefined : capacity,
      max_price: price === 0 ? undefined : price,
      date: dateValue === null ? "3000-01-01" : dateValue,
      time: `${addExtraNull(hour)}-${addExtraNull(minute)}`,
      type: String(sort.value),
    };
  }, [price, dateValue, sort, hour, minute]);

  const { data, isLoading, isError, isFetching } =
    useGetHallsQuery(setFilterQuery);

  if (data && data.length === 0) {
    return <div className={styles.empty}>Залы не найдены</div>;
  }

  return (
    <div className={styles.list}>
      {isFetching ? (
        <LoadingCardList />
      ) : (
        <>
          {data &&
            data.map((el) => {
              return (
                <AnimatedCard
                  key={el.id}
                  isFree={el.status === "FR"}
                  capacity={el.max_people}
                  price={el.price}
                  name={el.name}
                  to={`/hallInfo/${el.id}`}
                  photo={transformPathToPhoto(el.photo[0])}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

export default HallList;
